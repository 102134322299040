import '../styles/index.scss';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import Swiper from 'swiper';
import SwiperCore, { Pagination } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

let inDevMode = process.env.NODE_ENV === 'development';

// if (!inDevMode && window.location.hostname.indexOf('saymon.tech') === -1) {
//   window.location.replace("https://saymon.tech");
// }

if (inDevMode) {
  require('../index.html');
}

Sentry.init({
  dsn: "https://6942b8c6c9354eb381d80e20985db578@o368567.ingest.sentry.io/5742979",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

(() => {
  let db = firebase.firestore();
  let pricingQuestions = [
    {
      'question': 'What is your business scope?',
      'answers': ['System integrator', 'Service provider', 'Software vendor', 'Hardware vendor', 'Digital enterprise'],
    },
    {
      'question': 'Company size?',
      'answers': ['Small (1-20)', 'Mid-size (20-200)', 'Large (200+)',],
    },
    {
      'question': 'Market span?',
      'answers': ['Local', 'International',],
    },
  ];
  let pricingAnswers = {};

  document.addEventListener('DOMContentLoaded', () => {
    let pricingProgressEl = document.getElementById('pricing-progress-bar');

    for (let i = 0; i < pricingQuestions.length; i++) {
      let progressTileEl = document.createElement('div');
      if (i == 0) progressTileEl.classList.add('active');
      pricingProgressEl.append(progressTileEl);
    }

    generateSurvey(0);

    new Swiper("#clients-swiper", {
      speed: 200,
      allowTouchMove: false,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        // when window width is >= 600px
        600: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        985: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    });
    new Swiper("#team-swiper", {
      speed: 200,
      allowTouchMove: false,
      loop: true,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        // when window width is >= 600px
        600: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        985: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      },
    });
  });

  function generateSurvey(i) {
    let pricingQuestionsEl = document.getElementById('pricing-question');
    let pricingAnswersEl = document.getElementById('pricing-answers');

    pricingQuestionsEl.innerText = pricingQuestions[i].question;
    pricingAnswersEl.innerHTML = "";

    for (const answer of pricingQuestions[i].answers) {
      let answerEl = document.createElement('button');

      answerEl.classList.add('answer');
      answerEl.innerText = answer;
      answerEl.onclick = submitSurvey.bind(answerEl, i, answer);
      pricingAnswersEl.append(answerEl);
    }
  }

  function submitSurvey(i, answer) {
    let pricingProgressEls = document.querySelectorAll('#pricing-progress-bar > div');

    pricingAnswers[pricingQuestions[i].question] = answer;
    for (const el of pricingProgressEls) el.classList.remove('active');

    if (i < pricingQuestions.length) {
      pricingProgressEls[++i].classList.add('active');
      generateSurvey(i);
    } else {
      showSurveyResults();
    }
  }

  function showSurveyResults() {
    // TODO: finish this when survey results screen design gets finished
  }

  /**
   * Clears the location hash without changing page view position.
   */
  window.navigateClear = function () {
    location.hash = "_plain_";
    let uri = location.toString();
    history.replaceState({}, document.title, uri.substring(0, uri.indexOf("#")));
  };

  /**
   * Validates and submits the contacts form.
   */
  window.submitContactsForm = function (event) {
    event.preventDefault();

    let isValid = true;
    let params = {};
    let form = event.target;
    let button = event.target.querySelector("button");
    let alert = event.target.querySelector("[alert]");

    Array.from(form.elements).forEach((el) => {
      if (!el.checkValidity()) {
        el.classList.add("error");
        isValid = false;

        return;
      }

      params[el.name] = el.value;
      el.classList.remove("error");
    });

    if (!isValid) {
      alert.textContent =
        "Fill in all fields with *";
      form.classList.add("validation-error");

      return;
    }

    button.setAttribute("disabled", "true");
    form.classList.remove("validation-error");
    alert.textContent = "";
    db.collection("callbackRequests").add({
      name: params.name,
      telephone: params.telephone,
      comment: params.comment,
      time: firebase.firestore.FieldValue.serverTimestamp(),
      clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
      .then(() => {
        button.removeAttribute("disabled");
        location.replace("#order-success");
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            source: "contacts-form",
          }
        });
        button.removeAttribute("disabled");
        alert.textContent = "For some reason your request can not be submitted this time. But you can always call us by the number below.";
        form.classList.add("validation-error");
      })
      .then(() => {
        Array.from(form.elements).forEach((el) => el.value = '');
      });
  };
})();
